import Vue from 'vue'
import './element-ui/index.js'
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api/index.js'
import common from './assets/js/common'
Vue.config.productionTip = true;
Vue.prototype.$api = api;
Vue.use(common);
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')