import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/user/Login.vue'

// import HomeView from '../views/home/Index.vue'
import HomeData from '../views/home/Data.vue'

Vue.use(VueRouter)

// 捕获push replace中的错误
// 当然在replace中的错误也是可以相同的进行捕获
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
	return originalReplace.call(this, location).catch(err => err)
}

const routes = [
	// {
	// 	path: '/home', //跳转路径
	// 	name: 'home',
	// 	component: HomeView,
	// 	mobile: "/h5/"
	// },
	{
		path: '/', //跳转路径
		name: 'data',
		component: HomeData,
		mobile: "/h5/"
	},
	{
		path: '/login', //跳转路径
		name: 'Login',
		component: Login,
		mobile: "/h5/#/pages/user/login"
	},
]

const router = new VueRouter({
	routes
})

export default router

// const router = createRouter({
// 	history: createWebHashHistory(),
// 	routes
// })