import {
	// http,
	baseUrl,
	post
} from './axios'

//基础数据接口
const data = {
	getData: (params = {}) => post('/api/data/getData', params),
	getBase: (params = {}) => post('/api/data/getBase', params),
	getCer: (params = {}) => post('/api/data/getCer', params),
}

//所在地数据接口
const region = {
	getCate: (params = {}) => post('/api/region/getCate', params),
	findCateList: (params = {}) => post('/api/region/findCateList', params),
}

//注册数据接口
const login = {
	in: (params = {}) => post('/api/login/in', params),
	captcha: (params = {}) => post('/api/login/captcha', params),
	sendsms: (params = {}) => post('/api/login/sendsms', params),
}


//注册数据接口
const auth = {
	center: {
		set: (params = {}) => post('/api/auth.center/set', params),
		get: (params = {}) => post('/api/auth.center/get', params),
		upload: (params = {}) => post('/api/auth.center/upload', params),
	},
	itYearUser: {
		getItem: (params = {}) => post('/api/auth.itYearUser/getItem', params),
		submit: (params = {}) => post('/api/auth.itYearUser/submit', params),
	}
}


// 门户相关接口
const api = {
	baseUrl,
	data,
	region,
	login,
	auth,
}

export default api