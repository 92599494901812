export function baseCountry() {
	return {
		'list': [{
			'letter': '常用地区',
			'data': [
				"中国",
			]
		}, {
			'letter': 'A',
			'data': [
				'埃及',
				'奥地利',
				'阿根廷',
				'澳洲',
				'阿富汗',
				'阿尔及利亚',
				'安哥拉',
				'阿森松岛',
				'埃塞俄比亚',
				'阿鲁巴 (荷兰王国)',
				'爱尔兰共和国',
				'阿尔巴尼亚',
				'爱沙尼亚',
				'安道尔',
				'澳门 (中华人民共和国)',
				'阿曼',
				'阿拉伯联合酋长国',
				'阿塞拜疆',
				'安圭拉',
				'安提瓜和巴布达'
			]
		}, {
			'letter': 'B',
			'data': [
				'比利时',
				'波兰',
				'巴西',
				'巴基斯坦',
				'布基纳法索',
				'贝宁',
				'布隆迪',
				'博茨瓦纳',
				'冰岛',
				'保加利亚',
				'白俄罗斯',
				'波斯尼亚与赫塞哥维纳',
				'伯利兹',
				'巴拿马',
				'玻利维亚',
				'巴拉圭',
				'博内尔岛，圣尤斯特歇斯和',
				'巴布亚新几内亚',
				'巴勒斯坦',
				'巴林',
				'不丹',
				'巴哈马',
				'巴巴多斯',
				'百慕大 (英国)',
				'波多黎各 (美国)'
			]
		}, {
			'letter': 'C',
			'data': [
				'查德',
				'赤道几内亚'
			]
		}, {
			'letter': 'D',
			'data': [
				'丹麦',
				'德国',
				'多哥',
				'东帝汶',
				'东加',
				'多米尼克',
				'多米尼加共和国'
			]
		}, {
			'letter': 'E',
			'data': [
				'俄罗斯',
				'厄瓜多尔',
			]
		}, {
			'letter': 'F',
			'data': [
				'法国',
				'菲律宾',
				'佛得角',
				'法罗群岛 (丹麦)',
				'芬兰',
				'法属圭亚那 (法国)',
				'法属波利尼西亚 (法国)',
				'斐济'
			]
		}, {
			'letter': 'G',
			'data': [
				'古巴',
				'哥伦比亚',
				'冈比亚',
				'刚果共和国',
				'刚果民主共和国',
				'格陵兰 (丹麦)',
				'哥斯达黎加',
				'瓜德罗普岛',
				'圭亚那',
				'格林纳达',
				'关岛 (美国)'
			]
		}, {
			'letter': 'H',
			'data': [
				'哈萨克',
				'荷兰',
				'韩国',
				'黑山共和国',
				'洪都拉斯',
				'海地'
			]
		}, {
			'letter': 'I',
			'data': []
		}, {
			'letter': 'J',
			'data': [
				'加拿大',
				'几内亚',
				'加纳',
				'加蓬',
				'几内亚比绍',
				'吉布提',
				'津巴布韦',
				'捷克',
				'基里巴斯',
				'柬埔寨',
				'吉尔吉斯'
			]
		}, {
			'letter': 'K',
			'data': [
				'科特迪瓦',
				'喀麦隆',
				'肯尼亚',
				'科摩罗',
				'克罗地亚',
				'库拉索 (荷兰王国)',
				'库克群岛 (新西兰)',
				'科威特',
				'卡达',
				'开曼群岛 (英国)'
			]
		}, {
			'letter': 'L',
			'data': [
				'罗马尼亚',
				'利比亚',
				'利比里亚',
				'卢旺达',
				'留尼汪 (法国)',
				'莱索托',
				'卢森堡',
				'立陶宛',
				'拉脱维亚',
				'列支敦士登',
				'老挝',
				'黎巴嫩'
			]
		}, {
			'letter': 'M',
			'data': [
				'美国',
				'秘鲁',
				'墨西哥',
				'马来西亚',
				'缅甸',
				'摩洛哥',
				'毛里塔尼亚',
				'马里共和国',
				'毛里求斯',
				'莫桑比克',
				'马达加斯加',
				'马拉维',
				'马约特',
				'马其顿',
				'马耳他',
				'摩尔多瓦',
				'摩纳哥',
				'马提尼克 (法国)',
				'孟加拉国',
				'蒙古国',
				'美属维尔京群岛 (美国)',
				'蒙塞拉特岛 (英国)',
				'美属萨摩亚 (美国)'
			]
		}, {
			'letter': 'N',
			'data': [
				'南非',
				'挪威',
				'南苏丹',
				'尼日',
				'尼日利亚',
				'纳米比亚',
				'尼加拉瓜',
				'尼泊尔'
			]
		}, {
			'letter': 'O',
			'data': []
		}, {
			'letter': 'P',
			'data': [
				'葡萄牙',
				'帕劳'
			]
		}, {
			'letter': 'Q',
			'data': [
				'乔治亚'
			]
		}, {
			'letter': 'R',
			'data': [
				'瑞士',
				'瑞典',
				'日本'
			]
		}, {
			'letter': 'S',
			'data': [
				'斯里兰卡',
				'塞内加尔',
				'狮子山共和国',
				'圣多美普林西比',
				'塞舌尔',
				'苏丹',
				'索马里',
				'斯威士兰',
				'塞浦路斯',
				'圣马力诺',
				'塞尔维亚共和国',
				'斯洛文尼亚',
				'斯洛伐克',
				'萨尔瓦多',
				'圣皮耶与密克隆群岛 (法国)',
				'圣马丁岛(荷兰部分)',
				'苏里南',
				'所罗门群岛',
				'萨摩亚',
				'沙特阿拉伯',
				'圣卢西亚',
				'圣文森及格林纳丁',
				'圣克里斯多福与尼维斯'
			]
		}, {
			'letter': 'T',
			'data': [
				'泰国',
				'土耳其',
				'突尼斯',
				'坦桑尼亚',
				'台湾',
				'塔吉克',
				'土库曼',
				'土克凯可群岛 (英国)',
				'特立尼达和多巴哥'
			]
		}, {
			'letter': 'U',
			'data': []
		}, {
			'letter': 'V',
			'data': []
		}, {
			'letter': 'W',
			'data': [
				'委内瑞拉',
				'乌干达',
				'乌克兰',
				'危地马拉',
				'乌拉圭',
				'文莱',
				'瓦努阿图',
				'乌兹别克'
			]
		}, {
			'letter': 'X',
			'data': [
				'西安咸阳国际机场',
				'希腊',
				'西班牙',
				'匈牙利',
				'新西兰',
				'新加坡',
				'新喀里多尼亚 (法国)',
				'香港 (中华人民共和国)',
				'叙利亚'
			]
		}, {
			'letter': 'Y',
			'data': [
				'意大利',
				'英国',
				'印度尼西亚',
				'越南',
				'印度',
				'伊朗',
				'亚美尼亚',
				'约旦',
				'伊拉克',
				'也门',
				'以色列',
				'英属维尔京群岛 (英国)',
				'牙买加'
			]
		}, {
			'letter': 'Z',
			'data': [
				'智利',
				'中非共和国',
				'赞比亚',
				'直布罗陀 (英国)'
			]
		}]
	}
}