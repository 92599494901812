<template>
	<div id="app">
		<router-view ref="navBox" style="min-height: 77vh;" />
	</div>
</template>
<script>
	export default {
		name: 'App',
		components: {},
		mounted() {

		},
		methods: {

		}
	}
</script>
<style lang="less">
	html,
	body {
		margin: 0;
		font-size: 16px;
		min-width: 1640px;
		// background-color: #f1f1f1;
	}

	@import url('assets/css/base.less');
	@import url('assets/css/project.less');
</style>