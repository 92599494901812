export default {
	install(Vue) {
		//这里是示例方法 logout是方法名 function()可以携带参数
		Vue.prototype.logout = function() {
			localStorage.removeItem('u_token');
			this.$router.push({
				path: '/login',
				query: {}
			})
			location.reload();
		}
	}
}