<template>
	<div>
		<el-backtop></el-backtop>
		<div class="step_header border_bottom">
			<div class="flex_jc">
				<div class="main_w">
					<el-steps :active="step_active" align-center process-status="finish">
						<el-step title="单位基本情况"></el-step>
						<el-step title="人员及知识产权"></el-step>
						<el-step title="营收情况"></el-step>
						<el-step title="技术情况"></el-step>
					</el-steps>
				</div>
			</div>
			<div class="step_right flex_jc" v-if="user_info.id">
				<el-button type="danger" size="mini" round @click="donwCerFN()" v-if="formState.cer_no">下载证书</el-button>
				<div class="login_out com_pointer" @click="dialogVisible = true">退出登录</div>
			</div>
		</div>
		<div style="height:200px"></div>
		<div class="content">
			<div class="main_w mar_auto">
				<!-- 步骤一 -->
				<div v-if="step_active==0">
					<div>
						<div class="">
							<div class="text_c">
								<el-upload class="" :on-success="successFileUser" :on-error="errorFileUser"
									:before-upload="beforeUpload" :on-remove="removeFileUser"
									:on-exceed="exceedFileUser" drag :action="action_url" :multiple="false"
									:headers="{'api-name':'web','api-token':u_token}">
									<i class="el-icon-upload"></i>
									<div class="el-upload__text">
										<div>将承诺书拖到此处，或<em>点击上传</em></div>
										<div class="el-upload__tip color_A6A6A6" slot="tip">
											仅限支持拓展名：pdf、jpg、png，大小不高于5M的文件
										</div>
									</div>
								</el-upload>
								<div v-if="formState.file">
									<br>
									<div class="text_c font_s14">
										<a :href="formState.file" target="_blank" class="com_pointer"
											style="color: #409EFF!important;border-bottom:1px solid">文件已上传</a>
									</div>
								</div>
							</div>
							<br>
							<div class="flex_jc">
								<el-button type="primary" round @click="downLoadFN()">
									下载承诺书模板
								</el-button>
							</div>
							<div>
								<br>
								<el-divider></el-divider>
								<br>
							</div>
							<el-form label-position="right" autocomplete="off" label-width="240px">
								<el-form-item label="企业名称" required>
									<el-row :gutter="20">
										<el-col :span="12">
											<el-input v-model="formState.company_name" placeholder="请输入"></el-input>
										</el-col>
										<el-col :span="11">
											<el-checkbox-group v-model="formState.company_it">
												<el-checkbox label="人工智能"></el-checkbox>
												<el-checkbox label="大数据"></el-checkbox>
											</el-checkbox-group>
										</el-col>
									</el-row>
								</el-form-item>
								<div>
									<el-row :gutter="20">
										<el-col :span="12">
											<el-card shadow="never">
												<el-form-item label="注册地址" required>
													<div>
														<el-cascader prefix-icon="el-icon-lock" style="width: 100%;"
															placeholder="请选择所在地信息" v-model="formState.reg_area"
															:options="region_cates_list"
															:props="{label:'name',children:'sub',value:'name'}"
															:filterable="true" />
													</div>
													<div class="m-t-20">
														<el-input v-model="formState.reg_address"
															placeholder="请输入详细地址"></el-input>
													</div>
												</el-form-item>
											</el-card>
										</el-col>
										<el-col :span="12">
											<el-card shadow="never">
												<el-form-item label="经营地址" required>
													<div>
														<el-cascader prefix-icon="el-icon-lock" style="width: 100%;"
															placeholder="请选择所在地信息" v-model="formState.operate_area"
															:options="region_cates_list"
															:props="{label:'name',children:'sub',value:'name'}"
															:filterable="true" />
													</div>
													<div class="m-t-20">
														<el-input v-model="formState.operate_address"
															placeholder="请输入详细地址"></el-input>
													</div>
												</el-form-item>
											</el-card>
										</el-col>
									</el-row>
								</div>
								<br>
								<el-form-item label="社会信用代码" required>
									<el-row>
										<el-col :span="24">
											<el-input v-model="formState.social_credit_code"
												placeholder="请输入"></el-input>
										</el-col>
									</el-row>
								</el-form-item>
								<el-form-item label="企业来源 (申报时间为节点)" required>
									<el-row>
										<el-col :span="7" class="m-r-10">
											<!-- <el-radio-group v-model="formState.company_source_type">
											<el-radio :label="1">本市已注册企业(一年以上)</el-radio>
											<el-radio :label="2">本市本年度新注册企业</el-radio>
											<el-radio :label="3">本省市外迁入企业</el-radio>
											<el-radio :label="4">外省迁入企业</el-radio>
											</el-radio-group> -->
											<el-select v-model="formState.company_source_type" placeholder="请选择"
												style="width: 100%;">
												<el-option v-for="item in company_source_options" :key="item.value"
													:label="item.label" :value="item.value">
												</el-option>
											</el-select>
										</el-col>
										<el-col :span="6" class="m-r-10"
											v-if="formState.company_source_type == 3||formState.company_source_type==4">
											<el-date-picker v-model="formState.company_source_date" type="date"
												style="width: 100%;" placeholder="请选择迁入时间">
											</el-date-picker>
										</el-col>
										<el-col :span="7"
											v-if="formState.company_source_type == 3||formState.company_source_type==4">
											<el-cascader prefix-icon="el-icon-lock" style="width: 100%;"
												placeholder="请选择迁入前所在地信息" v-model="formState.company_source_area"
												:options="region_cates_list"
												:props="{label:'name',children:'sub',value:'name'}"
												:filterable="true" />

										</el-col>
										<el-col :span="24">
											<div class="text_l color_888">（此项针对本年度新迁入企业，可参考企查查上登记的变更时间）</div>
										</el-col>
									</el-row>
								</el-form-item>
								<el-form-item label="法人代表" required>
									<el-input v-model="formState.legal_representative"
										placeholder="请输入法人代表姓名"></el-input>
								</el-form-item>
								<el-form-item label="国籍" required>
									<!-- <el-select placeholder="请选择国籍" filterable style="width: 77.9%"
									v-model="formState.nationality">
									<el-option-group v-for="(group,index) in country_options.list" :key="index"
										:label="group.letter">
										<el-option v-for="item in group.data" :key="item" :label="item" :value="item">
										</el-option>
									</el-option-group>
								</el-select> -->
									<el-input v-model="formState.nationality" placeholder="请输入国籍"></el-input>
								</el-form-item>
								<el-form-item label="法人电话" required>
									<el-input v-model="formState.legal_phone" placeholder="请输入法人电话"></el-input>
								</el-form-item>
								<el-form-item label="联系人" required>
									<el-input v-model="formState.contact_person" placeholder="请输入联系人"></el-input>
								</el-form-item>
								<el-form-item label="联系人电话" required>
									<el-input v-model="formState.contact_phone" placeholder="请输入联系人电话"></el-input>
								</el-form-item>
								<el-form-item label="资产总额(按去年财务报表)" required>
									<el-input v-model="formState.total_assets" placeholder="请输入资产总额">
										<template slot="append">万元</template>
									</el-input>
								</el-form-item>
								<el-form-item label="固定资产(按去年财务报表)" required>
									<el-input v-model="formState.fixed_assets" placeholder="请输入固定资产">
										<template slot="append">万元</template>
									</el-input>
								</el-form-item>
								<el-form-item label="负债总额" required>
									<el-input v-model="formState.total_fuzhai" placeholder="请输入负债总额">
										<template slot="append">万元</template>
									</el-input>
								</el-form-item>
								<el-form-item label="银行贷款占比" required>
									<el-input v-model="formState.bank_daikuan_pro" placeholder="请输入银行贷款占比">
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
								<el-form-item label="企业估值(按照PS法进行估值)" required>
									<el-input v-model="formState.estimate_assets" placeholder="请输入企业估值">
										<template slot="append">万元</template>
									</el-input>
								</el-form-item>
								<el-form-item label="企业类型" required>
									<el-col :span="24" class="text_l">
										<el-radio-group v-model="formState.company_type">
											<el-radio :label="1">国有独资企业</el-radio>
											<el-radio :label="2">国有控股企业</el-radio>
											<el-radio :label="3">民营企业</el-radio>
											<el-radio :label="4">外资企业</el-radio>
										</el-radio-group>
									</el-col>
								</el-form-item>
								<el-form-item label="" required
									v-if="formState.company_type == '1'||formState.company_type == '2'||formState.company_type == '3'">
									<el-radio-group v-model="formState.company_type_str">
										<el-radio label="有限责任公司">有限责任公司</el-radio>
										<el-radio label="股份有限公司">股份有限公司</el-radio>
										<el-radio label="其他企业">其他企业</el-radio>
									</el-radio-group>
								</el-form-item>
								<el-form-item label="" required v-if="formState.company_type == '4'">
									<el-radio-group v-model="formState.company_type_str">
										<el-radio label="有限责任公司">有限责任公司</el-radio>
										<el-radio label="股份有限公司">股份有限公司</el-radio>
									</el-radio-group>
									<el-form-item required>
										<el-input v-model="formState.company_type_waizibili" placeholder="请输入外资比例">
											<template slot="append">%</template>
										</el-input>
									</el-form-item>
								</el-form-item>
								<el-form-item label="" required v-if="formState.company_type == '5'">
									<el-form-item required>
										<el-input v-model="formState.company_type_other" placeholder="请输入其他">
										</el-input>
									</el-form-item>
								</el-form-item>
								<el-form-item label="企业称号" required>
									<el-checkbox-group v-model="formState.enterprise_title">
										<el-checkbox label="科技型中小企业">
										</el-checkbox>
										<el-checkbox label="创新型中小企业">
										</el-checkbox>
										<el-checkbox label="国家高新技术企业">
										</el-checkbox>
										<el-checkbox label="专精特新中小企业">
										</el-checkbox>
										<el-checkbox label="专精特新中小巨人">
										</el-checkbox>
									</el-checkbox-group>
								</el-form-item>
								<el-form-item label="上市情况" required>
									<el-col :span="24" class="text_l">
										<el-radio-group v-model="formState.listing_situation">
											<el-radio label="主板"></el-radio>
											<el-radio label="创业板"></el-radio>
											<el-radio label="中小板"></el-radio>
											<el-radio label="科创板"></el-radio>
											<el-radio label="新三板挂牌"></el-radio>
											<el-radio label="尚未上市"></el-radio>
										</el-radio-group>
									</el-col>
								</el-form-item>
								<el-form-item label="股票代码">
									<el-input v-model="formState.stock_code" placeholder="请输入股票代码">
									</el-input>
								</el-form-item>
								<el-form-item label="融资情况" required>
									<el-col :span="24" class="text_l">
										<el-radio-group v-model="formState.financing_step">
											<el-radio label="天使轮">天使轮</el-radio>
											<el-radio label="A轮">A轮</el-radio>
											<el-radio label="B轮">B轮</el-radio>
											<el-radio label="C轮">C轮</el-radio>
											<el-radio label="D轮">D轮</el-radio>
											<el-radio label="无">无</el-radio>
										</el-radio-group>
									</el-col>
								</el-form-item>
								<el-form-item label="有银行贷款需求" required>
									<el-col :span="24" class="text_l">
										<el-radio-group v-model="formState.loan_demand">
											<el-radio label="是">是</el-radio>
											<el-radio label="否">否</el-radio>
										</el-radio-group>
									</el-col>
								</el-form-item>
								<el-form-item label="行标&国标制定" required>
									<el-row :gutter="0">
										<el-col :span="24">
											<el-radio-group v-model="formState.mark_make">
												<el-radio label="是">是</el-radio>
												<el-radio label="否">否</el-radio>
											</el-radio-group>
										</el-col>
									</el-row>
								</el-form-item>
								<el-form-item label="名称" required v-if="formState.mark_make === '是'">
									<el-input v-model="formState.mark_make_name" placeholder="请输入名称">
									</el-input>
								</el-form-item>
							</el-form>
						</div>
						<br><br>

						<br> <br>
					</div>
					<br>
				</div>
				<!-- 步骤二 -->
				<div v-if="step_active==1">
					<div>
						<div class="">
							<el-form label-position="right" autocomplete="off" label-width="180px">
								<div>
									<el-card shadow="never">
										<el-row :gutter="20">
											<el-col :span="12">
												<el-form-item label="职工总数" required>
													<el-input v-model="formState.people_company"
														placeholder="请输入企业职工人数"></el-input>
												</el-form-item>
											</el-col>
											<el-col :span="12">
												<el-form-item label="研发人员总数" required>
													<el-input v-model="formState.people_engineer"
														placeholder="请输入企业研发人员总数"></el-input>
												</el-form-item>
											</el-col>
										</el-row>
									</el-card>
								</div>
								<br>
								<div>
									<el-card shadow="never">
										<div>研发人员</div>
										<br>
										<el-row :gutter="20">
											<el-col :span="24">
												<el-form-item label="人工智能专属领域" required>
													<el-form-item label="研发负责人" required>
														<el-input v-model="formState.people_it_name"
															placeholder="请输入研发负责人"></el-input>
													</el-form-item>
													<el-form-item label="职位" required class="m-t-10">
														<el-input v-model="formState.people_it_job"
															placeholder="请输入职位"></el-input>
													</el-form-item>
													<el-form-item label="联系电话" required class="m-t-10">
														<el-input v-model="formState.people_it_phone"
															placeholder="请输入联系电话"></el-input>
													</el-form-item>
													<el-form-item label="过往项目介绍" required class="m-t-10">
														<el-input v-model="formState.people_it_content"
															placeholder="请输入过往项目介绍" maxlength="500"></el-input>
													</el-form-item>
													<!-- <el-input v-model="formState.people_it"
														placeholder="请输入专属领域人数"></el-input> -->
												</el-form-item>
											</el-col>
											<el-col :span="24">
												<el-form-item label="大数据专属领域" required>
													<el-form-item label="数据首席官" required>
														<el-input v-model="formState.people_big_data_name"
															placeholder="请输入研发负责人"></el-input>
													</el-form-item>
													<el-form-item label="职位" required class="m-t-10">
														<el-input v-model="formState.people_big_data_job"
															placeholder="请输入职位"></el-input>
													</el-form-item>
													<el-form-item label="联系电话" required class="m-t-10">
														<el-input v-model="formState.people_big_data_phone"
															placeholder="请输入联系电话"></el-input>
													</el-form-item>
													<!-- <el-input v-model="formState.people_big_data"
														placeholder="请输入专属领域人数"></el-input> -->
												</el-form-item>
											</el-col>
										</el-row>
									</el-card>
								</div>
								<br>
								<div>
									<el-card shadow="never">
										<div>大模型/算法</div>
										<br>
										<el-row :gutter="20">
											<el-col :span="12">
												<el-form-item label="" required>
													<el-input v-model="formState.record_num" placeholder="请输入">
														<template slot="append">个</template>
													</el-input>
												</el-form-item>
											</el-col>
											<el-col :span="12">
												<el-radio-group v-model="formState.record_step" class="m-t-13">
													<el-radio label="已备案">已备案</el-radio>
													<el-radio label="备案流程">备案流程</el-radio>
													<el-radio label="未备案">未备案</el-radio>
												</el-radio-group>
											</el-col>
										</el-row>
									</el-card>
								</div>
								<br>
								<div>
									<el-card shadow="never">
										<div>知识产权</div>
										<br>
										<el-row :gutter="20">
											<el-col :span="12">
												<el-form-item label="现有专利" required>
													<el-input v-model="formState.patent_now" placeholder="请输入现有专利数">
														<template slot="append">项</template>
													</el-input>
												</el-form-item>
											</el-col>
											<el-col :span="12">
												<el-form-item label="其中发明专利" required>
													<el-input v-model="formState.patent_invention"
														placeholder="请输入现有发明专利数">
														<template slot="append">项</template>
													</el-input>
												</el-form-item>
											</el-col>
											<el-col :span="12">
												<el-form-item label="PCT" required>
													<el-input v-model="formState.patent_pct" placeholder="请输入PCT">
														<template slot="append">项</template>
													</el-input>
												</el-form-item>
											</el-col>
											<el-col :span="12">
												<el-form-item label="新计划及申请中" required>
													<el-input v-model="formState.patent_apply" placeholder="请输入专利数">
														<template slot="append">项</template>
													</el-input>
												</el-form-item>
											</el-col>
										</el-row>
									</el-card>
								</div>
								<br>
							</el-form>
						</div>
					</div>
					<br>
				</div>
				<!-- 步骤三 -->
				<div v-if="step_active==2">
					<div>
						<div class="">
							<el-form label-position="right" autocomplete="off" label-width="220px">
								<div>
									<el-card shadow="never">
										<div>主要经济指标-2023年</div>
										<br>
										<el-row :gutter="20">
											<el-col :span="24">
												<el-form-item label="主营业务营收" required>
													<el-input v-model="formState.conpany_total_2023"
														placeholder="请输入主营业务营收">
														<template slot="append">万元</template>
													</el-input>
												</el-form-item>
											</el-col>
											<el-col :span="24">
												<el-form-item label="人工智能业务营收" required>
													<el-input v-model="formState.compant_total_it_2023"
														placeholder="请输入人工智能业务营收">
														<template slot="append">万元</template>
													</el-input>
												</el-form-item>
											</el-col>
											<el-col :span="24">
												<el-form-item label="主研发投入" required>
													<el-input v-model="formState.compant_total_research_2023"
														placeholder="请输入主研发投入">
														<template slot="append">万元</template>
													</el-input>
												</el-form-item>
											</el-col>
										</el-row>
									</el-card>
								</div>
								<br>
								<div>
									<el-card shadow="never">
										<div>主要经济指标-2024年1~8月</div>
										<br>
										<el-row :gutter="20">
											<el-col :span="24">
												<el-form-item label="主营业务营收" required>
													<el-input v-model="formState.conpany_total_2024"
														placeholder="请输入主营业务营收">
														<template slot="append">万元</template>
													</el-input>
												</el-form-item>
											</el-col>
											<el-col :span="24">
												<el-form-item label="人工智能业务营收" required>
													<el-input v-model="formState.compant_total_it_2024"
														placeholder="请输入人工智能业务营收">
														<template slot="append">万元</template>
													</el-input>
												</el-form-item>
											</el-col>
											<el-col :span="24">
												<el-form-item label="主研发投入" required>
													<el-input v-model="formState.compant_total_research_2024"
														placeholder="请输入主研发投入">
														<template slot="append">万元</template>
													</el-input>
												</el-form-item>
											</el-col>
										</el-row>
									</el-card>
								</div>
								<br>
							</el-form>
						</div>
					</div>
					<br>
				</div>
				<div v-if="step_active==3">
					<div>
						<el-form label-position="right" autocomplete="off" label-width="220px">
							<el-form-item label="数据量" required>
								<el-input v-model="formState.data_size" placeholder="请输入">
									<template slot="append">GB/TB</template>
								</el-input>
							</el-form-item>
							<el-form-item label="数据管理人员" required>
								<el-input v-model="formState.data_admin" placeholder="请输入">
									<template slot="append">人</template>
								</el-input>
							</el-form-item>
							<el-form-item label="DCMM认证(1-5级)" required>
								<el-col :span="24" class="text_l">
									<el-radio-group v-model="formState.dcmm_level">
										<el-radio label="初始级">初始级</el-radio>
										<el-radio label="受管理级">受管理级</el-radio>
										<el-radio label="稳健级">稳健级</el-radio>
										<el-radio label="量化管理级">量化管理级</el-radio>
										<el-radio label="优化级">优化级</el-radio>
										<el-radio label="未获认证">未获认证</el-radio>
									</el-radio-group>
								</el-col>
							</el-form-item>
						</el-form>
					</div>
					<div v-if="formState.company_it.indexOf('人工智能')!=-1">
						<div class="font_weight">人工智能技术领域</div>
						<br>
						<div class="">
							<el-form label-position="right" autocomplete="off" label-width="60px">
								<div>
									<el-card shadow="never">
										<br>
										<el-row :gutter="20">
											<el-col :span="24">
												<el-form-item>
													<el-checkbox-group v-model="formState.ai_arr">
														<el-form-item>
															<el-checkbox label="赋能新型工业化"></el-checkbox>
															<el-checkbox-group class="m-l-40"
																v-model="formState.arrIt_gongyehua">
																<el-checkbox label="研发设计">
																</el-checkbox>
																<el-checkbox label="中试验证">
																</el-checkbox>
																<el-checkbox label="生产制造">
																</el-checkbox>
																<el-checkbox label="营销服务">
																</el-checkbox>
																<el-checkbox label="运营管理">
																</el-checkbox>
															</el-checkbox-group>
														</el-form-item>
														<el-form-item class="m-t-10">
															<el-checkbox label="是否属于重点行业"></el-checkbox>
															<el-checkbox-group class="m-l-40"
																v-model="formState.arrIt_zhongdianhangye">
																<el-checkbox label="装备行业">
																</el-checkbox>
																<el-checkbox label="消费品行业">
																</el-checkbox>
																<el-checkbox label="原材料行业">
																</el-checkbox>
															</el-checkbox-group>
														</el-form-item>
														<el-form-item class="m-t-10">
															<el-checkbox label="行业应用"></el-checkbox>
															<el-checkbox-group class="m-l-40"
																v-model="formState.arrIt_hangyeyingyong">
																<el-checkbox label="智慧城市">
																</el-checkbox>
																<el-checkbox label="科学智慧">
																</el-checkbox>
																<el-checkbox label="智慧农业">
																</el-checkbox>
																<el-checkbox label="智慧能源">
																</el-checkbox>
																<el-checkbox label="智慧环保">
																</el-checkbox>
																<el-checkbox label="智慧金融">
																</el-checkbox>
																<el-checkbox label="智慧物流">
																</el-checkbox>
																<el-checkbox label="智慧教育">
																</el-checkbox>
																<el-checkbox label="智慧医疗">
																</el-checkbox>
																<el-checkbox label="智慧交通">
																</el-checkbox>
															</el-checkbox-group>
														</el-form-item>
														<el-form-item class="m-t-10">
															<el-checkbox label="智能产品与服务"></el-checkbox>
															<el-checkbox-group class="m-l-40"
																v-model="formState.arrIt_ai">
																<el-checkbox label="智能机器人">
																</el-checkbox>
																<el-checkbox label="智能运载工具">
																</el-checkbox>
																<el-checkbox label="智能移动终端">
																</el-checkbox>
																<el-checkbox label="数字人">
																</el-checkbox>
																<el-checkbox label="智能服务">
																</el-checkbox>
															</el-checkbox-group>
														</el-form-item>
														<el-form-item class="m-t-10">
															<el-checkbox label="关键技术"></el-checkbox>
															<el-checkbox-group class="m-l-40"
																v-model="formState.arrIt_guanjianjishu">
																<el-checkbox label="机器学习">
																</el-checkbox>
																<el-checkbox label="知识图谱">
																</el-checkbox>
																<el-checkbox label="大模型">
																</el-checkbox>
																<el-checkbox label="自然语言处理">
																</el-checkbox>
																<el-checkbox label="智能语音">
																</el-checkbox>
																<el-checkbox label="计算机视觉">
																</el-checkbox>
																<el-checkbox label="生物特征识别">
																</el-checkbox>
																<el-checkbox label="人机混合增强智能">
																</el-checkbox>
																<el-checkbox label="智能体">
																</el-checkbox>
																<el-checkbox label="群体智能">
																</el-checkbox>
																<el-checkbox label="跨媒体智能">
																</el-checkbox>
																<el-checkbox label="具身智能">
																</el-checkbox>
															</el-checkbox-group>
														</el-form-item>
														<el-form-item class="m-t-10">
															<el-checkbox label="接入数据中台"></el-checkbox>
															<el-checkbox-group class="m-l-40"
																v-model="formState.arrIt_shujuzhongtai">
																<el-checkbox label="政务">
																</el-checkbox>
																<el-checkbox label="制造">
																</el-checkbox>
																<el-checkbox label="汽车">
																</el-checkbox>
																<el-checkbox label="金融">
																</el-checkbox>
																<el-checkbox label="地产">
																</el-checkbox>
																<el-checkbox label="零售">
																</el-checkbox>
																<el-checkbox label="医药">
																</el-checkbox>
																<el-checkbox label="其他">
																</el-checkbox>
																<el-checkbox label="未接入">
																</el-checkbox>
															</el-checkbox-group>
														</el-form-item>
														<el-form-item class="m-t-10">
															<el-checkbox label="训练数据来源"></el-checkbox>
															<el-checkbox-group class="m-l-40"
																v-model="formState.arrIt_xunlianshuju">
																<el-checkbox label="企业自有">
																</el-checkbox>
																<el-checkbox label="网络爬取">
																</el-checkbox>
																<el-checkbox label="外部付费">
																</el-checkbox>
																<el-checkbox label="开源数据">
																</el-checkbox>
																<el-checkbox label="AI合成">
																</el-checkbox>
															</el-checkbox-group>
														</el-form-item>
														<el-form-item class="m-t-10">
															<el-checkbox label="数据使用存在难题"></el-checkbox>
															<el-checkbox-group class="m-l-40"
																v-model="formState.arrIt_nanti">
																<el-checkbox label="数据架构">
																</el-checkbox>
																<el-checkbox label="数据安全">
																</el-checkbox>
																<el-checkbox label="数据质量">
																</el-checkbox>
																<el-checkbox label="数据标准">
																</el-checkbox>
															</el-checkbox-group>
														</el-form-item>
														<el-form-item class="m-t-10">
															<el-checkbox label="加密算法"></el-checkbox>
															<el-checkbox-group class="m-l-40"
																v-model="formState.arrIt_jiamisuanfa">
																<el-checkbox label="DES">
																</el-checkbox>
																<el-checkbox label="3DES">
																</el-checkbox>
																<el-checkbox label="RC2&RC4">
																</el-checkbox>
																<el-checkbox label="IDEA">
																</el-checkbox>
																<el-checkbox label="RSA">
																</el-checkbox>
																<el-checkbox label="DSA">
																</el-checkbox>
																<el-checkbox label="AES">
																</el-checkbox>
																<el-checkbox label="其他">
																</el-checkbox>
															</el-checkbox-group>
														</el-form-item>
														<el-form-item class="m-t-10">
															<el-checkbox label="客户类型"></el-checkbox>
															<el-checkbox-group class="m-l-40"
																v-model="formState.arrIt_kehuleixing">
																<el-checkbox label="B端">
																</el-checkbox>
																<el-checkbox label="C端">
																</el-checkbox>
																<el-checkbox label="国内">
																</el-checkbox>
																<el-checkbox label="出海">
																</el-checkbox>
															</el-checkbox-group>
														</el-form-item>
														<el-form-item class="m-t-10">
															<el-checkbox label="收费模式"></el-checkbox>
															<el-checkbox-group class="m-l-40"
																v-model="formState.arrIt_shoufeimoshi">
																<el-checkbox label="免费">
																</el-checkbox>
																<el-checkbox label="会员订阅">
																</el-checkbox>
																<el-checkbox label="资源购买">
																</el-checkbox>
																<el-checkbox label="定制化解决方案">
																</el-checkbox>
																<el-checkbox label="API接口调用">
																</el-checkbox>
															</el-checkbox-group>
														</el-form-item>
													</el-checkbox-group>
												</el-form-item>
											</el-col>
										</el-row>
									</el-card>
								</div>
								<br>
							</el-form>
						</div>
					</div>
					<div v-if="formState.company_it.indexOf('大数据')!=-1">
						<br>
						<div class="font_weight">大数据技术领域</div>
						<br>
						<div class="">
							<el-form label-position="right" autocomplete="off" label-width="60px">
								<div>
									<el-card shadow="never">
										<br>
										<el-row :gutter="10">
											<el-col :span="24">
												<el-form-item>
													<el-form-item label="" required>
														<div><span class="color_red">*</span> 通过国家大数据系统质量监督检验检测中心产品检测
														</div>
														<el-radio-group v-model="formState.data_check">
															<el-radio label="是">是</el-radio>
															<el-radio label="否">否</el-radio>
														</el-radio-group>
													</el-form-item>
													<el-form-item label="" class="m-t-10">
														<el-checkbox-group v-model="formState.data_arr">
															<el-form-item>
																<el-checkbox label="数据源层"></el-checkbox>
																<el-checkbox-group class="m-l-40"
																	v-model="formState.arrData_shujuyuanceng">
																	<el-checkbox label="企业数据">
																	</el-checkbox>
																	<el-checkbox label="移动通信数据">
																	</el-checkbox>
																	<el-checkbox label="政府数据">
																	</el-checkbox>
																	<el-checkbox label="行业联盟数据数据交易中心数据">
																	</el-checkbox>
																	<el-checkbox label="第三方数据服务">
																	</el-checkbox>
																</el-checkbox-group>
															</el-form-item>
															<el-form-item class="m-t-10">
																<el-checkbox label="基础设施层"></el-checkbox>
																<el-checkbox-group class="m-l-40"
																	v-model="formState.arrData_jichusheshiceng">
																	<el-checkbox label="储备层">
																	</el-checkbox>
																	<el-checkbox label="运算设备">
																	</el-checkbox>
																	<el-checkbox label="一体机">
																	</el-checkbox>
																	<el-checkbox label="基础软件">
																	</el-checkbox>
																	<el-checkbox label="IT服务">
																	</el-checkbox>
																	<el-checkbox label="其他">
																	</el-checkbox>
																</el-checkbox-group>
															</el-form-item>
															<el-form-item class="m-t-10">
																<el-checkbox label="组织管理层"></el-checkbox>
																<el-checkbox-group class="m-l-40"
																	v-model="formState.arrData_zuzhiguanliceng">
																	<el-checkbox label="分布式文件系统">
																	</el-checkbox>
																	<el-checkbox label="分布式计算系统">
																	</el-checkbox>
																	<el-checkbox label="数据库">
																	</el-checkbox>
																	<el-checkbox label="数据仓库数据转换工具">
																	</el-checkbox>
																	<el-checkbox label="数据安全">
																	</el-checkbox>
																</el-checkbox-group>
															</el-form-item>
															<el-form-item class="m-t-10">
																<el-checkbox label="分析发现层"></el-checkbox>
																<el-checkbox-group class="m-l-40"
																	v-model="formState.arrData_fenxifaxianceng">
																	<el-checkbox label="数据挖掘">
																	</el-checkbox>
																	<el-checkbox label="数据统计">
																	</el-checkbox>
																	<el-checkbox label="BI">
																	</el-checkbox>
																	<el-checkbox label="AI">
																	</el-checkbox>
																	<el-checkbox label="内容知识管理">
																	</el-checkbox>
																	<el-checkbox label="数据可视化">
																	</el-checkbox>
																</el-checkbox-group>
															</el-form-item>
															<el-form-item class="m-t-10">
																<el-checkbox label="平台服务层"></el-checkbox>
																<el-checkbox-group class="m-l-40"
																	v-model="formState.arrData_pingtaifuwuceng">
																	<el-checkbox label="数据分享平台">
																	</el-checkbox>
																	<el-checkbox label="数据分析平台">
																	</el-checkbox>
																	<el-checkbox label="分析预测服务">
																	</el-checkbox>
																	<el-checkbox label="数据租售(交易)平台">
																	</el-checkbox>
																	<el-checkbox label="决策支持服务">
																	</el-checkbox>
																</el-checkbox-group>
															</el-form-item>
															<el-form-item class="m-t-10">
																<el-checkbox label="数据应用层"></el-checkbox>
																<el-checkbox-group class="m-l-40"
																	v-model="formState.arrData_shujuzhongtai">
																	<el-form-item>
																		<el-checkbox label="行业应用"></el-checkbox>
																		<el-checkbox-group class="m-l-40"
																			v-model="formState.arrData_hangyeyingyong">
																			<el-checkbox label="安防"></el-checkbox>
																			<el-checkbox label="征信"></el-checkbox>
																			<el-checkbox label="电信"></el-checkbox>
																			<el-checkbox label="金融"></el-checkbox>
																			<el-checkbox label="人力资源"></el-checkbox>
																			<el-checkbox label="农业"></el-checkbox>
																			<el-checkbox label="医疗"></el-checkbox>
																			<el-checkbox label="汽车"></el-checkbox>
																			<el-checkbox label="工业"></el-checkbox>
																			<el-checkbox label="其他"></el-checkbox>
																		</el-checkbox-group>
																	</el-form-item>
																	<el-form-item>
																		<el-checkbox label="垂直化应用"></el-checkbox>
																	</el-form-item>
																	<el-form-item>
																		<el-checkbox label="精准化营销"></el-checkbox>
																	</el-form-item>
																	<el-form-item>
																		<el-checkbox label="舆情监控"></el-checkbox>
																	</el-form-item>
																	<el-form-item>
																		<el-checkbox label="网站/app分析工具"></el-checkbox>
																	</el-form-item>
																	<el-form-item>
																		<el-checkbox label="地理数据采集"></el-checkbox>
																	</el-form-item>
																	<el-form-item>
																		<el-checkbox label="风险控制/反欺诈"></el-checkbox>
																	</el-form-item>
																	<el-form-item>
																		<el-checkbox label="计算机视觉识别"></el-checkbox>
																	</el-form-item>
																	<el-form-item>
																		<el-checkbox label="智能语音识别"></el-checkbox>
																	</el-form-item>
																	<el-form-item>
																		<el-checkbox label="商业WIFI"></el-checkbox>
																	</el-form-item>
																	<el-form-item>
																		<el-checkbox label="移动开发者服务"></el-checkbox>
																	</el-form-item>
																</el-checkbox-group>
															</el-form-item>
															<el-form-item>
																<el-checkbox label="解决方案"></el-checkbox>
																<el-checkbox-group class="m-l-40"
																	v-model="formState.arrData_jiejuefangan">
																	<el-checkbox label="智慧社区"></el-checkbox>
																	<el-checkbox label="智慧教育"></el-checkbox>
																	<el-checkbox label="智慧政务"></el-checkbox>
																	<el-checkbox label="智慧城市"></el-checkbox>
																	<el-checkbox label="智慧物流"></el-checkbox>
																</el-checkbox-group>
															</el-form-item>
														</el-checkbox-group>
													</el-form-item>
													</el-checkbox-group>
												</el-form-item>
												</el-form-item>
											</el-col>
										</el-row>
									</el-card>
								</div>
								<br>
							</el-form>
						</div>
					</div>
					<br>
					<!-- 	<div class="com_none">
						<div class="font_weight">企业 AI 算力需求情况</div>
						<div class="">
							<el-form label-position="right" autocomplete="off" label-width="180px">
								<br>
								<div>
									<el-card shadow="never">
										<div>
											<div>1、算力中心算力资源使用情况：</div>
											<br>
											<el-row :gutter="20">
												<el-col :span="11">
													<el-form-item>
														<el-checkbox-group v-model="formState.power_situation">
															<el-row :gutter="20">
																<el-col :span="8">
																	<el-checkbox label="共享资源">
																	</el-checkbox>
																</el-col>
																<el-col :span="16">
																	<el-form-item label="" required>
																		<el-input v-model="formState.resources_share"
																			placeholder="请输入">
																			<template slot="append">P</template>
																		</el-input>
																	</el-form-item>
																</el-col>
															</el-row>
															<el-row :gutter="20">
																<el-col :span="8">
																	<el-checkbox label="专属资源">
																	</el-checkbox>
																</el-col>
																<el-col :span="16">
																	<el-form-item label="" required>
																		<el-input
																			v-model="formState.resources_exclusive"
																			placeholder="请输入">
																			<template slot="append">P</template>
																		</el-input>
																	</el-form-item>
																</el-col>
															</el-row>
															<el-row :gutter="20">
																<el-col :span="8">
																	<el-checkbox label="裸金属资源">
																	</el-checkbox>
																</el-col>
																<el-col :span="16">
																	<el-form-item label="" required>
																		<el-input
																			v-model="formState.resources_bare_metal"
																			placeholder="请输入">
																			<template slot="append">台</template>
																		</el-input>
																	</el-form-item>
																</el-col>
															</el-row>
														</el-checkbox-group>
													</el-form-item>
												</el-col>
											</el-row>
										</div>
										<div>
											<div>2、使用 AI 的主要算法大类是什么？（多选）：</div>
											<br>
											<el-row :gutter="20">
												<el-col :span="24">
													<el-form-item>
														<el-checkbox-group v-model="formState.ai_cate">
															<el-checkbox label="机器视觉">
															</el-checkbox>
															<el-checkbox label="自然语言处理">
															</el-checkbox>
															<el-checkbox label="强化学习">
															</el-checkbox>
															<el-checkbox label="OCR文字识别">
															</el-checkbox>
															<el-checkbox label="AI 决策">
															</el-checkbox>
															<el-checkbox label="其他技术领域">
															</el-checkbox>
														</el-checkbox-group>
													</el-form-item>
												</el-col>
											</el-row>
										</div>
										<div>
											<div>3、是否具有大模型研发计划？</div>
											<br>
											<el-row :gutter="20">
												<el-col :span="24">
													<el-form-item>
														<el-radio-group v-model="formState.big_model_plan">
															<el-radio :label="0">否</el-radio>
															<el-radio :label="1">是</el-radio>
														</el-radio-group>
													</el-form-item>
												</el-col>
												<el-col :span="8" v-if="formState.big_model_plan == 1">
													<el-form-item label="具体大模型类别：" required>
														<el-input v-model="formState.big_model_remark"
															placeholder="请输入">
														</el-input>
													</el-form-item>
												</el-col>
												<el-col :span="16" v-if="formState.big_model_plan == 1">
													<el-form-item label="参考量大小">
														<el-radio-group v-model="formState.big_model_reference">
															<el-radio label="10B以下"></el-radio>
															<el-radio label="10B-100B"></el-radio>
															<el-radio label="100B-500B"></el-radio>
															<el-radio label="500B以上"></el-radio>
														</el-radio-group>
													</el-form-item>
												</el-col>
											</el-row>
										</div>
										<div>
											<div>4、算力使用存在的问题：</div>
											<br>
											<el-row :gutter="20">
												<el-col :span="24">
													<el-form-item>
														<el-radio-group v-model="formState.power_problem">
															<el-radio label="AI 框架兼容性问题"></el-radio>
															<el-radio label="技术文档不全"></el-radio>
															<el-radio label="算子不支持"></el-radio>
															<el-radio label="其他"></el-radio>
														</el-radio-group>
													</el-form-item>
													<div v-if="formState.power_problem.indexOf('其他')!=-1">
														<el-form-item required>
															<el-input v-model="formState.power_problem_remark"
																placeholder="请输入">
															</el-input>
														</el-form-item>
													</div>
												</el-col>
											</el-row>
										</div>
										<div>
											<div>5、AI 能力主要应用场景是什么？（多选）</div>
											<br>
											<el-row :gutter="20">
												<el-col :span="24">
													<el-form-item>
														<el-checkbox-group v-model="formState.ai_ability_scene">
															<el-checkbox label="科研课题">
															</el-checkbox>
															<el-checkbox label="智慧医疗">
															</el-checkbox>
															<el-checkbox label="智慧交通">
															</el-checkbox>
															<el-checkbox label="智慧金融">
															</el-checkbox>
															<el-checkbox label="智慧空管">
															</el-checkbox>
															<el-checkbox label="智慧农业">
															</el-checkbox>
															<el-checkbox label="智慧旅游">
															</el-checkbox>
															<el-checkbox label="智慧教育">
															</el-checkbox>
															<el-checkbox label="智慧水务">
															</el-checkbox>
															<el-checkbox label="智慧政务">
															</el-checkbox>
															<el-checkbox label="其他">
															</el-checkbox>
														</el-checkbox-group>
													</el-form-item>
													<div v-if="formState.ai_ability_scene.indexOf('其他')!=-1">
														<el-form-item required>
															<el-input v-model="formState.ai_ability_scene_remark"
																placeholder="请输入">
															</el-input>
														</el-form-item>
													</div>
												</el-col>
											</el-row>
										</div>
										<br>
										<div>
											<div>6、AI 算力应用场景（多选）</div>
											<br>
											<el-row :gutter="20">
												<el-col :span="24">
													<el-form-item>
														<el-checkbox-group v-model="formState.ai_power_scence">
															<el-checkbox label="训练">
															</el-checkbox>
															<el-checkbox label="推理">
															</el-checkbox>
															<el-checkbox label="渲染">
															</el-checkbox>
															<el-checkbox label="其他">
															</el-checkbox>
														</el-checkbox-group>
													</el-form-item>
													<div v-if="formState.ai_power_scence.indexOf('其他')!=-1">
														<el-form-item required>
															<el-input v-model="formState.ai_power_scence_remark"
																placeholder="请输入">
															</el-input>
														</el-form-item>
													</div>
												</el-col>
											</el-row>
										</div>
										<br>
										<div>
											<div>7、当前训练场景下使用的框架是（多选）</div>
											<br>
											<el-row :gutter="20">
												<el-col :span="24">
													<el-form-item>
														<el-checkbox-group v-model="formState.technology_frame">
															<el-checkbox label="TensorFlow">
															</el-checkbox>
															<el-checkbox label="PyTorch">
															</el-checkbox>
															<el-checkbox label="Caffe">
															</el-checkbox>
															<el-checkbox label="MindSpore">
															</el-checkbox>
															<el-checkbox label="PaddlePaddle">
															</el-checkbox>
															<el-checkbox label="MegEngine">
															</el-checkbox>
															<el-checkbox label="DeepSpeed">
															</el-checkbox>
															<el-checkbox label="Transformers">
															</el-checkbox>
															<el-checkbox label="其他">
															</el-checkbox>
														</el-checkbox-group>
													</el-form-item>
													<div v-if="formState.technology_frame.indexOf('其他')!=-1">
														<el-form-item required>
															<el-input v-model="formState.technology_frame_remark"
																placeholder="请输入">
															</el-input>
														</el-form-item>
													</div>
												</el-col>
											</el-row>
										</div>
										<br>
										<div>
											<div>
												<div>8、（1）OAI算力来源（多选）</div>
												<br>
												<el-row :gutter="20">
													<el-col :span="24">
														<el-form-item label="">
															<el-checkbox-group @change="checkBoxOtherFN('oai_source')"
																v-model="formState.oai_source">
																<el-checkbox label="自建">
																</el-checkbox>
																<el-checkbox label="租用">
																</el-checkbox>
															</el-checkbox-group>
														</el-form-item>
													</el-col>
												</el-row>
											</div>
											<div v-if="formState.oai_source.indexOf('租用')!=-1">
												<div>
													<div>8、（2）如果是租用方式，当前租用算力来源（多选）</div>
													<br>
													<el-row>
														<el-col :span="24">
															<el-form-item label="">
																<el-checkbox-group v-model="formState.rent_source">
																	<el-checkbox label="广州超算中心">
																	</el-checkbox>
																	<el-checkbox label="广州人工智能公共算力中心">
																	</el-checkbox>
																	<el-checkbox label="华为云">
																	</el-checkbox>
																	<el-checkbox label="阿里云">
																	</el-checkbox>
																	<el-checkbox label="百度云">
																	</el-checkbox>
																	<el-checkbox label="腾讯云">
																	</el-checkbox>
																	<el-checkbox label="其他">
																	</el-checkbox>
																</el-checkbox-group>
															</el-form-item>
														</el-col>
														<el-col :span="10"
															v-if="formState.rent_source.indexOf('其他')!=-1">
															<el-form-item required>
																<el-input v-model="formState.rent_source_remark"
																	placeholder="请输入其他">
																</el-input>
															</el-form-item>
														</el-col>
													</el-row>
												</div>
												<div>
													<div>8、（3）</div>
													<br>
													<el-row :gutter="20">
														<el-col :span="11">
															<el-form-item required label="当前一年的租用成本是">
																<el-input v-model="formState.rent_cost"
																	placeholder="请输入">
																	<template slot="append">万</template>
																</el-input>
															</el-form-item>
														</el-col>
														<el-col :span="11">
															<el-form-item required label="租用的算力大小">
																<el-input v-model="formState.rent_power"
																	placeholder="请输入">
																	<template slot="append">P</template>
																</el-input>
															</el-form-item>
														</el-col>
													</el-row>
												</div>
											</div>
										</div>
										<br>
										<div>
											<div>
												<div>9、（1）未来对AI 算力的需求（多选）</div>
												<br>
												<el-row :gutter="20">
													<el-col :span="18">
														<el-form-item>
															<el-checkbox-group v-model="formState.future_ai_require">
																<el-row :gutter="20">
																	<el-col :span="4">
																		<el-checkbox label="英伟达技术路线">
																		</el-checkbox>
																	</el-col>
																	<el-col :span="16">
																		<el-form-item label="算力需求" required>
																			<el-input
																				v-model="formState.nvidia_require_remark"
																				placeholder="请输入">
																				<template slot="append">P</template>
																			</el-input>
																		</el-form-item>
																	</el-col>
																</el-row>
																<el-row :gutter="20">
																	<el-col :span="4">
																		<el-checkbox label="国产技术路线">
																		</el-checkbox>
																	</el-col>
																	<el-col :span="16">
																		<el-form-item label="算力要求" required>
																			<el-input
																				v-model="formState.home_require_remark"
																				placeholder="请输入">
																				<template slot="append">P</template>
																			</el-input>
																		</el-form-item>
																	</el-col>
																</el-row>
															</el-checkbox-group>
														</el-form-item>
														<el-form-item label="" required
															v-if="formState.future_ai_require.indexOf('国产技术路线')!=-1">
															<el-checkbox-group v-model="formState.home_require_cate">
																<el-checkbox label="华为昇腾">
																</el-checkbox>
																<el-checkbox label="寒武纪">
																</el-checkbox>
																<el-checkbox label="燧原">
																</el-checkbox>
																<el-checkbox label="百度昆仑">
																</el-checkbox>
																<el-checkbox label="其他">
																</el-checkbox>
															</el-checkbox-group>
														</el-form-item>
													</el-col>
												</el-row>
											</div>
											<br>
											<div>
												<div>
													<div>9、（2）2024年对AI 算力的需求（多选）</div>
													<br>
													<el-row :gutter="20">
														<el-col :span="18">
															<el-form-item>
																<el-checkbox-group v-model="formState.ai_require_2024">
																	<el-row :gutter="20">
																		<el-col :span="4">
																			<el-checkbox label="英伟达技术路线">
																			</el-checkbox>
																		</el-col>
																		<el-col :span="16">
																			<el-form-item label="算力需求" required>
																				<el-input
																					v-model="formState.nvidia_require_remark_2024"
																					placeholder="请输入">
																					<template slot="append">P</template>
																				</el-input>
																			</el-form-item>
																		</el-col>
																	</el-row>
																	<el-row :gutter="20">
																		<el-col :span="4">
																			<el-checkbox label="国产技术路线">
																			</el-checkbox>
																		</el-col>
																		<el-col :span="16">
																			<el-form-item label="算力要求" required>
																				<el-input
																					v-model="formState.home_require_remark_2024"
																					placeholder="请输入">
																					<template slot="append">P</template>
																				</el-input>
																			</el-form-item>
																		</el-col>
																	</el-row>
																</el-checkbox-group>
															</el-form-item>
															<el-form-item label="" required
																v-if="formState.ai_require_2024.indexOf('国产技术路线')!=-1">
																<el-checkbox-group
																	v-model="formState.home_require_cate_2024">
																	<el-checkbox label="华为昇腾">
																	</el-checkbox>
																	<el-checkbox label="寒武纪">
																	</el-checkbox>
																	<el-checkbox label="燧原">
																	</el-checkbox>
																	<el-checkbox label="百度昆仑">
																	</el-checkbox>
																	<el-checkbox label="其他">
																	</el-checkbox>
																</el-checkbox-group>
															</el-form-item>
														</el-col>
													</el-row>
												</div>
											</div>
										</div>
									</el-card>
								</div>
								<br>
							</el-form>
						</div>
					</div> -->
					<br>
				</div>
				<el-divider></el-divider>
				<br>
				<div class="text_c">
					<el-row type="flex" justify="center">
						<el-col :span="6">
							<el-button @click="prevBTN()" style="width: 70%;" round
								:disabled="step_active >0?false:true">上一步</el-button>
						</el-col>
						<el-col :span="6">
							<el-button type="primary" style="width: 70%;" v-if="step_active<3" round @click="nextBTN()">
								<span>下一步</span>
							</el-button>
							<el-button type="primary" style="width: 70%;" v-if="step_active>=3 && formState.status<2"
								round @click="nextBTN()">
								<span>立即提交</span>
							</el-button>
						</el-col>
						<el-col :span="6" v-if="formState.status<2">
							<el-button type="success" style="width: 70%;" round @click="saveBTN()">保存进度</el-button>
						</el-col>
					</el-row>
				</div>
			</div>
			<br><br><br>
		</div>
		<!-- 退出登录 -->
		<el-dialog title="温馨提示" :visible.sync="dialogVisible" width="30%">
			<span>确定要退出登录吗？</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="danger" @click="logoutFN()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		baseCountry
	} from '@/element-ui/country.js';
	export default {
		name: 'HomeData',
		components: {},
		props: [''],
		data() {
			return {
				dialogVisible: false,
				value1: "", //迁入时间
				step_active: 0, //步骤
				region_cates_list: [], //所在地
				u_token: localStorage.getItem('u_token'),
				action_url: '',
				id: "",
				info: {},
				visible: false,
				file_user: "",
				formState_edit_index: -1,
				formState: {
					status: 0,
					file: "", //承诺书
					//步骤一：
					company_name: "", //企业名称
					company_it: [], //企业性质
					reg_area: "", //注册地址
					reg_address: "",
					operate_area: "", //经营地址
					operate_address: "",
					social_credit_code: "", //社会信用代码
					company_source_type: 1, //企业来源
					company_source_date: "", //迁入时间
					company_source_area: "", //迁入地址
					legal_representative: "", //法人代表
					nationality: "", //国籍
					legal_phone: "", //法人电话
					contact_person: "", //联系人
					contact_phone: "", //联系人电话
					total_assets: "", //资产总额
					fixed_assets: "", //固定资产
					total_fuzhai: "", //负债总额，new
					bank_daikuan_pro: "", //银行带宽占比，new


					estimate_assets: "", //企业估值
					company_type: 1, //企业类型
					company_type_str: "", //企业类型名称，new
					company_type_waizibili: "", //外资企业-外资比例，new
					company_type_other: "", //其他企业-请说明，new
					enterprise_title: [], //企业称号，new
					listing_situation: 1, //上市情况
					stock_code: "", //股票代码，new
					financing_step: "", //融资情况，new
					loan_demand: "", //贷款需求，new
					mark_make: "", //是否有行标、国标，new
					mark_make_name: "", //行标、国标制定名称，new

					//步骤二：
					people_company: "", //职工总数
					people_engineer: "", //研发人员总数
					people_it: "", //人工智能专属领域
					people_it_name: "", //人工智能专属领域-研发负责人，new
					people_it_job: "", //人工智能专属领域-职位，new
					people_it_phone: "", //人工智能专属领域-联系电话，new
					people_it_content: "", //人工智能专属领域-过往项目介绍，new
					people_big_data: "", //大数据专属领域
					people_big_data_name: "", //大数据专属领域-数据首席官，new
					people_big_data_job: "", //大数据专属领域-职位，new
					people_big_data_phone: "", //大数据专属领域-联系电话，new
					record_num: "", //大模型/算法，备案数量，new
					record_step: "", //大模型/算法，备案步骤，new
					patent_now: "", //现有专利
					patent_invention: "", //其中发明专利
					patent_pct: "", //PCT，new
					patent_apply: "", //新计划及申请中
					//步骤三：
					conpany_total_2023: "", //企业总营收
					compant_total_it_2023: "", //人工智能业务营收
					compant_total_research_2023: "", //研发投入
					conpany_total_2024: "", //企业总营收，new
					compant_total_it_2024: "", //人工智能业务营收，new
					compant_total_research_2024: "", //研发投入，new
					//步骤四：
					data_size: "", //数据量（GB/TB），new
					data_admin: "", //数据管理人员，new
					dcmm_level: "", //DCMM认证，new
					ai_arr: [], //人工智能技术领域，new
					arrIt_gongyehua: [], //赋能新型工业化，new
					arrIt_zhongdianhangye: [], //是否属于重点行业，new
					arrIt_hangyeyingyong: [], //行业应用，new
					arrIt_ai: [], //智能产品与服务，new
					arrIt_guanjianjishu: [], //关键技术，new
					arrIt_shujuzhongtai: [], //接入数据中台，new
					arrIt_xunlianshuju: [], //训练数据来源，new
					arrIt_nanti: [], //数据使用存在难题，new
					arrIt_jiamisuanfa: [], //加密算法，new
					arrIt_kehuleixing: [], //客户类型，new
					arrIt_shoufeimoshi: [], //收费模式，new

					data_check: "", //通过国家大数据系统质量监督检验检测中心产品检测，new
					data_arr: [], //大数据技术领域，new
					arrData_shujuyuanceng: [], //数据源层，new
					arrData_jichusheshiceng: [], //基础设施层，new
					arrData_zuzhiguanliceng: [], //组织管理层，new
					arrData_fenxifaxianceng: [], //分析发现层，new
					arrData_pingtaifuwuceng: [], //平台服务层，new
					arrData_shujuzhongtai: [], //数据应用层，new
					arrData_hangyeyingyong: [], //行业应用，new
					arrData_jiejuefangan: [], //解决方案，new


					// it_direction: "", //人工智能技术领域
					// data_direction: "", //大数据技术领域
					// power_situation: [], //算力中心算力资源使用情况
					// resources_share: "", //共享资源P
					// resources_exclusive: "", //专属资源P
					// resources_bare_metal: "", //裸金属资源台
					// ai_cate: [], //AI主要算法大类
					// big_model_plan: 1, //是否具有大模型研发计划
					// big_model_remark: "", //具体大模型类别
					// big_model_reference: "10B以下", //参考量大小
					// power_problem: "", //算力使用存在的问题
					// power_problem_remark: "", //其他
					// ai_ability_scene: [], //AI 能力主要应用场景
					// ai_ability_scene_remark: "", //其他
					// ai_power_scence: [], //AI 算力应用场景
					// ai_power_scence_remark: "", //其他
					// technology_frame: [], //当前训练场景下使用的框架
					// technology_frame_remark: "", //其他
					// oai_source: [], //OAI算力来源
					// rent_source: [], //租用算力来源
					// rent_source_remark: "", //其他
					// rent_cost: "", //一年的租用成本
					// rent_power: "", //租用的算力大小
					// future_ai_require: [], //未来算力的需求
					// nvidia_require_remark: "", //英伟达技术路线
					// home_require_remark: "", //国产技术路线
					// home_require_cate: [], //国产类型
					// ai_require_2024: [],
					// nvidia_require_remark_2024: "", //英伟达技术路线
					// home_require_remark_2024: "", //国产技术路线
					// home_require_cate_2024: [], //国产类型
				},
				country_options: baseCountry(), //国籍
				company_source_options: [{
					value: 1,
					label: '本市已注册企业(一年以上)'
				}, {
					value: 2,
					label: '本市本年度新注册企业'
				}, {
					value: 3,
					label: '本省市外迁入企业'
				}, {
					value: 4,
					label: '外省迁入企业'
				}],
				user_info: {}, //用户个人信息
			}
		},
		created() {
			this.action_url = this.$api.baseUrl + '/api/auth.center/upload';
		},
		computed: {},
		beforeMount() {},
		mounted() {
			let _this = this;
			_this.$nextTick(() => {
				setTimeout(function() {
					_this.getUserInfo();
					_this.getAreaCateIndexFN();
					_this.readLogFN();
				}, 100);
			});
		},
		methods: {
			//下载证书
			donwCerFN() {
				if (!this.formState.cer_no) {
					this.$message.error('暂无证书编号');
					return;
				}
				const params = {
					cer_no: this.formState.cer_no,
				};
				this.$api.data.getCer(params).then((res) => {
					if (res.code == 1) {
						if (res.data.img_url) {
							const link = document.createElement('a')
							link.style.display = 'none'
							link.href = this.$api.baseUrl + '/' + res.data.img_url
							link.setAttribute(
								'download',
								this.formState.cer_no
							)
							document.body.appendChild(link)
							link.click()
						} else {
							this.$message.error('暂无证书可下载');
						}
					} else {
						this.$message.error(res.info);
					}
					// 处理相应的业务
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//下载承诺书模版
			downLoadFN() {
				const params = {
					name: "link",
				};
				this.$api.data.getData(params).then((res) => {
					if (res.code == 1) {
						if (res.data.template_file) {
							// window.open(res.data.template_file);
							const link = document.createElement('a')
							link.style.display = 'none'
							link.href = res.data.template_file
							link.setAttribute(
								'download',
								'承诺书模版资料'
							)
							document.body.appendChild(link)
							link.click()
						} else {
							this.$message.error('暂无模版资料可下载');
						}
					} else {
						this.$message.error(res.info);
					}
					// 处理相应的业务
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//退出登录
			logoutFN() {
				localStorage.removeItem('u_token');
				this.$router.push({
					path: '/login',
					query: {}
				})
				location.reload();
			},
			//获取个人信息
			getUserInfo() {
				const params = {}
				this.$api.auth.center.get(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.user_info = res.data;
					} else {
						this.logoutFN();
					}
				}).catch(() => {
					this.$message.error('请重新登录！');
					this.logoutFN();
				}).finally(() => {
					// 关闭loading
				})
			},
			//读取进度
			readLogFN() {
				const params = {}
				this.$api.auth.itYearUser.getItem(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						var data = res.data.list[0]
						Object.assign(this.formState, data.content); //重置
						this.step_active = data.step_active;
					} else {
						this.logoutFN();
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//触发条件选择
			checkBoxOtherFN(type) {
				console.log(type)
			},
			//上一步
			prevBTN() {
				if (this.step_active <= 0) {
					return;
				}
				this.scrollToTopFN();
				this.step_active--;
			},
			//下一步&立即提交
			nextBTN() {
				if (this.step_active >= 3) {
					this.$confirm('提交后将不能再次提交修改, 是否提交?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					}).then(() => {
						this.postCheckFN('', 1);
					}).catch(() => {});
					return;
				}
				this.scrollToTopFN();
				this.step_active++;
			},
			//保存进度
			saveBTN() {
				this.postCheckFN('保存成功', 0);
			},
			//返回顶部js代码
			scrollToTopFN() {
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				});
			},
			//获取所在地数据
			getAreaCateIndexFN() {
				const params = {}
				this.$api.region.getCate(params).then((res) => {
					// 处理相应的业务
					this.region_cates_list = res.data;
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//文件上传成功回调
			successFileUser(res) {
				this.$message.success('文件上传成功');
				this.formState.file = res.data.url;
			},
			errorFileUser() {
				this.$message.error('当前网络异常，请检查网络，重新上传');
			},
			//文件超出个数限制时的钩子	
			exceedFileUser() {
				this.$message.warning('超出1个文件，请先删除当前文件，再重新上传');
			},
			//删除信息表
			removeFileUser() {
				this.formState.file = '';
			},
			beforeUpload(file) {
				var FileExt = file.name.replace(/.+\./, "");
				if (['pdf', 'jpg', 'png'].indexOf(
						FileExt
						.toLowerCase()) === -
					1) {
					this.$message({
						type: 'error',
						message: '上传失败，请上传后缀名为pdf、jpg、png的附件！'
					});
					return false;
				}
				if ((file.size / 1024 / 1024) > 5) {
					this.$message({
						message: '上传失败，上传文件大小不能超过5M',
						type: 'error'
					});
					return false;
				}
			},
			//提交数据
			postCheckFN(msg, is_submit) {
				const params = {};
				params.is_submit = is_submit;
				params.data = JSON.stringify(this.formState);
				params.step_active = this.step_active;
				this.$api.auth.itYearUser.submit(params).then((res) => {
					if (res.code == 1) {
						if (is_submit == 1) {
							this.formState.status = 2;
							this.step_active = 0;
						}
						this.$message.success(msg ? msg : res.info);
					} else {
						this.$message.error(res.info);
					}
					// 处理相应的业务
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
				//重置
				// this.formState = this.$options.data().formState;
			},
			//多选-全选
			handleCheckAllChange(val, c_key) {
				var cityOptions = [];
				this.formState[c_key] = val ? cityOptions : [];
				this.isIndeterminate = false;
			},
			//多选-单个
			handleCheckedCitiesChange(value, p_key) {
				let checkedCount = value.length;
				this.formState[p_key] = checkedCount === this.cities.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
			}
		},
		watch: {}
	}
</script>
<style scoped>
	>>>.el-form-item__label {
		white-space: pre-line;
	}
</style>