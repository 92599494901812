<template>
	<div class="">
		<br><br>
		<div class="p-30">
			<div class="text_c font_weight font_s24">2023-2024年广州市人工智能、大数据企业入库系统</div>
			<div class="text_c font_weight m-t-20">首批截止申报时间: 2024年11月10日</div>
		</div>
		<div class="box-shadow mar_auto bgcolor_w" style="width: 450px;padding: 30px 60px;">
			<div class="flex_between">
				<div class="font_weight font_s20">登录/注册</div>
				<div class="text_c">
					<img src="@/assets/img/logo.jpg" style="width: 92px;height: 92px;" alt="">
				</div>
			</div>
			<el-row type="flex" justify="center">
				<el-col :span="24">
					<el-form :model="ruleForm" status-icon ref="ruleForm" label-position="top" label-width="100px"
						class="demo-ruleForm">
						<el-form-item label="">
							<el-input prefix-icon="el-icon-user" v-model="ruleForm.phone" placeholder="请输入手机号码"
								maxlength="11"></el-input>
						</el-form-item>
						<el-form-item>
							<el-col :span="14">
								<el-form-item>
									<el-input prefix-icon="el-icon-s-promotion" type="text" placeholder="请输入短信验证码"
										v-model="ruleForm.sms_code" maxlength="6" auto-complete="false"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="10">
								<div class="text_c">
									<el-button type="primary" plain @click="sendMsg"
										:disabled="disabled">{{btnText}}</el-button>
								</div>
							</el-col>
						</el-form-item>
						<el-form-item>
							<el-col :span="14">
								<el-form-item>
									<el-input prefix-icon="el-icon-picture-outline" type="text" placeholder="请输入图片验证码"
										v-model="ruleForm.verify" maxlength="4" auto-complete="false"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="10">
								<div class="text_c">
									<img :src="imgUrl" style="height: 41px;" @click="resetImg" />
								</div>
							</el-col>
						</el-form-item>
						<br>
						<el-form-item class="text_c">
							<el-button type="primary"
								style="width: 300px;background-color: #0A70BA;border-color: #0A70BA;"
								@click="submitForm('ruleForm')">立即登录</el-button>
						</el-form-item>
						<el-form-item class="">
							<div class="font_s12 text_c com_nowrap"><span
									class="color_red">*</span>用同一个手机号登录，已保存未录入完成的资料可继续录入</div>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<div class="text_c color_A6A6A6 font_s11">技术支持：非比信息科技</div>
		</div>
		<br><br>
	</div>
</template>
<script>
	export default {
		name: "UserLogin",
		data() {
			return {
				imgUrl: "",
				disabled: false,
				btnText: "发送验证码",
				countDown: 60,
				time: 60,
				ruleForm: {
					uniqid: "",
					phone: "",
					verify: "",
					pass: "",
					sms_code: "",
				},
			};
		},
		mounted() {
			this.resetImg();
		},
		methods: {
			//发送验证码
			sendMsg() {
				if (this.disabled) {
					return;
				}
				if (!this.isPhone(this.ruleForm.phone)) {
					this.$message.error('手机号码输入不正确');
					return;
				}
				const params = {
					phone: this.ruleForm.phone,
				}
				this.$api.login.sendsms(params).then((res) => {
					if (res.code == 1) {
						let timer = setInterval(() => {
							this.time--;
							this.btnText = `${this.time}s后重新发送`
							this.disabled = true
							if (this.time === 0) {
								this.disabled = false;
								this.btnText = '重新发送';
								this.time = this.countDown
								clearInterval(timer)
							}
						}, 1000);
						this.$message.info('发送验证码成功');
					} else {
						this.$message.error(res.info);
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//刷新验证码
			resetImg() {
				this.$api.login.captcha({}).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.imgUrl = res.data.image;
						this.ruleForm.uniqid = res.data.uniqid;
						this.ruleForm.verify = '';
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//判断是否为手机号
			isPhone(tel) {
				if (tel === '' || tel === undefined) {
					return false;
				}
				let is = /^1[3-9]\d{9}$/.test(tel);
				if (!is) {
					return false;
				}
				return true;
			},
			//提交检验
			submitForm() {
				if (this.ruleForm.phone == '') {
					this.$message.error('请输入手机号码');
					return;
				}
				if (!this.isPhone(this.ruleForm.phone)) {
					this.$message.error('手机号码输入不正确');
					return;
				}
				if (this.ruleForm.sms_code == '') {
					this.$message.error('请输入短信验证码');
					return;
				}
				this.loginFN();
			},
			//注册接口
			loginFN() {
				const params = {
					phone: this.ruleForm.phone,
					verify: this.ruleForm.verify,
					uniqid: this.ruleForm.uniqid,
					sms_code: this.ruleForm.sms_code,
				}
				this.$api.login.in(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.$message({
							message: '登录成功',
							type: 'success'
						});
						localStorage.setItem('u_token', res.data.token.token);
						this.$router.push({
							path: '/',
						})
						location.reload();
						// var _this = this;
						// setTimeout(function() {
						// 	_this.$router.push({
						// 		path: '/',
						// 	})
						// 	location.reload();
						// }, 100);
					} else {
						this.resetImg();
						this.$message({
							message: res.info,
							type: 'error'
						});
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		}
	}
</script>
<style scoped>
	.el-input__inner {
		border-bottom: 1px solid #DCDFE6;
		border-top: 0;
		border-right: 0;
		border-left: 0;
		border-radius: 0;
	}
</style>